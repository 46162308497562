export const locationRows = {
  topRow1: {
    W1: ["03", "02", "01"],
    V1: ["06", "03", "05", "02", "04", "01"],
    U1: ["06", "03", "05", "02", "04", "01"],
    T1: ["06", "03", "05", "02", "04", "01"],
  },
  topRow2: {
    W2: ["03", "02", "01"],
    V2: ["06", "03", "05", "02", "04", "01"],
    U2: ["06", "03", "05", "02", "04", "01"],
    T2: ["06", "03", "05", "02", "04", "01"],
  },
  topRow3: {
    W3: ["-", "-", "-"],
    V3: ["-", "-", "05", "02", "04", "01"],
    U3: ["-", "-", "05", "02", "04", "01"],
    T3: ["-", "-", "05", "02", "04", "01"],
  },
  rightRow1: {
    S1: ["06", "03", "05", "02", "04", "01"],
    R1: ["06", "03", "05", "02", "04", "01"],
    Q1: ["06", "03", "05", "02", "04", "01"],
    P1: ["06", "03", "05", "02", "04", "01"],
    O1: ["06", "03", "05", "02", "04", "01"],
    N1: ["06", "03", "05", "02", "04", "01"],
  },
  rightRow2: {
    S2: ["06", "03", "05", "02", "04", "01"],
    R2: ["06", "03", "05", "02", "04", "01"],
    Q2: ["06", "03", "05", "02", "04", "01"],
    P2: ["06", "03", "05", "02", "04", "01"],
    O2: ["06", "03", "05", "02", "04", "01"],
    N2: ["06", "03", "05", "02", "04", "01"],
  },
  rightRow3: {
    S3: ["-", "-", "05", "02", "04", "01"],
    R3: ["-", "-", "05", "02", "04", "01"],
    Q3: ["-", "-", "-", "-", "-", "-"],
    P3: ["-", "-", "05", "02", "04", "01"],
    O3: ["-", "-", "05", "02", "04", "01"],
    N3: ["-", "-", "05", "02", "04", "01"],
  },
  bottomRow1: {
    A1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    B1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    C1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    D1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    E1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    F1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    G1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    H1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    I1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    J1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    K1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    L1: ["01", "05", "02", "06", "03", "07", "04", "08"],
    M1: ["01", "05", "02", "06", "03", "07", "04", "08"],
  },
  bottomRow2: {
    A2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    B2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    C2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    D2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    E2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    F2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    G2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    H2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    I2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    J2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    K2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    L2: ["01", "05", "02", "06", "03", "07", "04", "08"],
    M2: ["01", "05", "02", "06", "03", "07", "04", "08"],
  },
  bottomRow3: {
    A3: ["-", "-", "-", "-", "-", "-", "-", "-"],
    B3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    C3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    D3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    E3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    F3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    G3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    H3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    I3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    J3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    K3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    L3: ["01", "05", "02", "06", "03", "07", "04", "08"],
    M3: ["01", "05", "02", "06", "03", "07", "04", "08"],
  },
};
